import React, { useContext } from "react";
import { Box } from "../../../components/elements/box/Box";
import { ThemeContext } from "styled-components";
import {
  BodyRegular,
  BodyBold,
  HeaderSubBold,
} from "../../../components/elements/typography/Typography";
import { Flex } from "../../../components/layouts/flex/Flex";
import { chatMessage } from "./chatMessage";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Loading } from "../../../components/elements/loading/Loading";

type Props = {
  chatMessage: chatMessage;
};
export const ChatMessage = (props: Props) => {
  const { chatMessage } = props;
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const convertMessage = (text: string) => {
    const parts = text.split(/(<br\/>|<BodyBold>|<\/BodyBold>)/g);
    let bold = false;

    return parts.map((part, index) => {
      if (part === "<br/>") {
        return <br key={index} />;
      } else if (part === "<BodyBold>") {
        bold = true;
        return null;
      } else if (part === "</BodyBold>") {
        bold = false;
        return null;
      } else {
        return (
          <React.Fragment key={index}>
            {bold ? <BodyBold>{part}</BodyBold> : part}
          </React.Fragment>
        );
      }
    });
  };

  return (
    <Box
      style={{
        minWidth: "25%",
        maxWidth: "75%",
        alignSelf: chatMessage.role === "user" ? "end" : "start",
        backgroundColor: theme.bg1,
      }}
    >
      <Flex w100 h100 justify="between" column gap="16px">
        <HeaderSubBold>
          {chatMessage.role === "assistant"
            ? "WASP AI"
            : chatMessage.role === "tool"
              ? "Tool"
              : chatMessage.fullName
                ? chatMessage.fullName
                : `${me?.first_name} ${me?.last_name}`}
        </HeaderSubBold>
        <Flex w100 gap="8px">
          {chatMessage.text === "Thinking..." && <Loading noTitle />}
          <BodyRegular
            style={{
              wordBreak: "break-word",
              whiteSpace: "normal",
              fontSize: "15px",
              lineHeight: "2",
            }}
          >
            {convertMessage(chatMessage.text)}
          </BodyRegular>
        </Flex>
      </Flex>
    </Box>
  );
};
