import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
  updateContext,
} from "./utils";
import {
  createItem,
  deleteBulk,
  deleteItem,
  deleteUrl,
  getItems,
  getPage,
  getSingleItem,
  updateBulk,
  updateItem,
  updateUrl,
} from "./sdk";
import {
  BulkUpdateFindings,
  Finding,
  FindingPage,
  FindingsCounts,
  FindingsFunnelStatistics,
  FindingsOverTimeStatsItem,
  FindingImage,
  AdminFindingEdit,
  Attachment,
} from "../../types/Finding";
import { bitAfterNow, getQueryParams } from "../../shared/helper";

const key = "findings";
const countKey = "findings/counts";
const statisticsKey = "findings/statistics";
const idsKey = "findings/get_ids";
const asmAnalyticsKey = "findings/asm_analytics";
const secBenchmarkKey = "findings/security_benchmark";
const labels_cvesKey = "findings/labels_cves";

export interface AdminUserFindingParams
  extends Omit<Finding, "id" | "updated_at"> {
  onSuccessCallback?: (data: Finding) => void;
  onErrorCallback?: (error: Error) => void;
}

export interface RegularUserFindingParams {
  status?: string;
  comment?: string;
  labels?: string[];
  assignee?: string;
}

export interface UpdateFindingParams extends AdminFindingEdit {
  id: number;
  onSuccessCallback?: (data: Finding) => void;
  onErrorCallback?: (error: Error) => void;
}

export interface FindingContext {
  findingId: number;
  findingData: RegularUserFindingParams;
  onSuccessCallback?: (data: Finding) => void;
  onErrorCallback?: (error: Error) => void;
}

interface FindingsBulkContext {
  findingsData: BulkUpdateFindings;
  onSuccessCallback?: (data: Finding[]) => void;
  onErrorCallback?: (error: Error) => void;
}

interface FindingsBulkDeleteContext {
  findingsData: BulkUpdateFindings;
  onSuccessCallback?: (data: number | undefined) => void;
  onErrorCallback?: (error: Error) => void;
}

export type FindingsMergeInput = {
  finding_ids: number[];
  is_pending: boolean;
  new_title?: string;
};
export type FindingsMergeOutput = {
  merged_finding_id: number;
};

export type FindingMergeContext = {
  findingsData: FindingsMergeInput;
  onSuccessCallback?: (data: FindingsMergeOutput) => void;
  onErrorCallback?: (error: Error) => void;
};
interface FindingsPushToJiraContext {
  findingsId: number[];
  projectKey: string;
  onSuccessCallback?: (data: any) => void;
  onErrorCallback?: (error: Error) => void;
}

export interface FindingImageParams {
  findingId: number;
  base64: string;
  type: string;
  onSuccessCallback?: (data: FindingImage) => void;
  onErrorCallback?: (error: Error) => void;
}

export interface FindingAttachmentCreateParams {
  findingId: number;
  attachments: Attachment[];
  onSuccessCallback?: (data: string) => void;
  onErrorCallback?: (error: Error) => void;
}

export interface FindingAttachmentParams {
  findingId: number;
  attachment_name: string;
  onSuccessCallback?: (data: Blob | string) => void;
  onErrorCallback?: (error: Error) => void;
}

interface FindingsLabelsCves {
  labels: string[];
  cves: string[];
}

interface DateCount {
  date: string; // ISO date string
  count: number;
}

interface customerDatesData {
  customer_id: number;
  dates: DateCount[];
}

export interface analyticsOvertime {
  created: customerDatesData[];
  valid: customerDatesData[];
  dismissed: customerDatesData[];
}

export interface analyticsCurrentItem {
  customer_id: number;
  tp_count: number;
  fp_count: number;
  presented_count: number;
  in_progress_count: number;
  re_test_count: number;
  acceptable_risk_count: number;
  dismissed_count: number;
  fixed_count: number;
  info_count: number;
  low_count: number;
  medium_count: number;
  high_count: number;
  critical_count: number;
  pending_count: number;
  valid_count: number;
}

interface asmAnalytics {
  overtime_data: analyticsOvertime;
  current_data: analyticsCurrentItem[];
}

interface secBenchmarkMetrics {
  risk_score: { [key: string]: number };
  coverage_score: { [key: string]: number };
  mttr: { [key: string]: number };
  count_open_findings: { [key: string]: number };
  avg_age_open_finding: { [key: string]: number };
}

export const useApiSingleFinding = (
  id: number | undefined,
  isAdminMode: boolean = false
) =>
  useQuery<Finding | undefined, Error>({
    queryKey: [key, id],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    refetchInterval: 60000 * 60, // 1 hour
    enabled: !!id,
    queryFn: async (): Promise<Finding | undefined> =>
      getSingleItem(
        key,
        (id?.toString() || "") + (isAdminMode ? "?admin-mode=true" : "")
      ).then((data) => (data ? (data as Finding) : undefined)),
  });

export const useApiFindings = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useQuery<Finding[], Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    placeholderData: [],
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async (): Promise<Finding[]> =>
      getItems(key, filters) as Promise<Finding[]>,
  });

export const useApiFindingsIds = (filters?: { [key: string]: any }) =>
  useQuery<number[], Error>({
    queryKey: [idsKey, filters],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<number[]> => getItems(idsKey, filters),
  });

export const useApiFindingsCounts = (filters?: { [key: string]: any }) =>
  useQuery<FindingsCounts, Error>({
    queryKey: [countKey, filters || {}],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<FindingsCounts> => getItems(countKey, filters),
  });

export const useApiFindingsFunnelStatistics = (filters?: {
  [key: string]: any;
}) =>
  useQuery<FindingsFunnelStatistics, Error>({
    queryKey: [statisticsKey, filters],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<FindingsFunnelStatistics> =>
      getItems(statisticsKey, filters),
  });

export const useApiFindingsOverTimeStatistics = (filters?: {
  [key: string]: any;
}) =>
  useQuery<FindingsOverTimeStatsItem[], Error>({
    queryKey: [statisticsKey, filters],
    keepPreviousData: false,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<FindingsOverTimeStatsItem[]> =>
      getItems(statisticsKey, filters),
  });

export const useApiFindingsLabelsCves = () =>
  useQuery<FindingsLabelsCves, Error>({
    queryKey: [labels_cvesKey],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<FindingsLabelsCves> =>
      getItems(labels_cvesKey, {}),
  });

export const useApiFindingsPaging = (
  filters?: { [key: string]: any },
  enabled: boolean = true
) =>
  useInfiniteQuery<FindingPage, Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: enabled,
    queryFn: async ({ pageParam = 1 }): Promise<FindingPage> =>
      getPage(key, { ...filters, page: pageParam }).then((resp) => {
        var page = { ...resp };
        const findingsDicts = page?.results || [];
        page.results = findingsDicts as Finding[];
        return page;
      }),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.next
        ? parseInt(getQueryParams(lastPage.next)?.page || "1")
        : null,
  });

export const useApiUpdateFindingStatus = ({
  findingId,
  onSuccessCallBack,
  onErrorCallBack,
}: {
  findingId: number;
  onSuccessCallBack?: () => void;
  onErrorCallBack?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation<Finding, Error, FindingContext>({
    mutationKey: [key, findingId],
    mutationFn: async ({
      findingId,
      findingData,
    }: FindingContext): Promise<Finding> =>
      await updateUrl(`${key}/${findingId}/change_status/`, findingData),
    onSuccess: (data: Finding) => {
      updateContext({ data, queryKey: [key, findingId], queryClient });
      onSuccessCallBack && onSuccessCallBack();
    },
    onError: (error) => onErrorCallBack && onErrorCallBack(error),
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
      // invalid the comments too for re-fetching
      invalidateApiQueries(
        ["findings-comments", { finding: findingId }],
        queryClient
      );
    },
  });
};

export const useApiUpdateFindingsBulk = (filters?: { [key: string]: any }) => {
  const queryClient = useQueryClient();
  return useMutation<Finding[], Error, FindingsBulkContext>({
    mutationKey: [key, filters],
    mutationFn: async ({
      findingsData,
    }: FindingsBulkContext): Promise<Finding[]> =>
      await updateBulk(`${key}/bulk`, findingsData, filters),
    onSuccess: (data: Finding[], { onSuccessCallback: onSuccessCallBack }) => {
      updateContext({ data, queryKey: [key], queryClient });
      onSuccessCallBack && onSuccessCallBack(data);
    },
    onError: (error: Error, { onErrorCallback: onErrorCallBack }) => {
      onErrorCallBack && onErrorCallBack(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
    },
  });
};

export const useApiDeleteFindingsBulk = (filters?: { [key: string]: any }) => {
  const queryClient = useQueryClient();
  return useMutation<number | undefined, Error, FindingsBulkDeleteContext>({
    mutationKey: [key, filters],
    mutationFn: async ({
      findingsData,
    }: FindingsBulkDeleteContext): Promise<number | undefined> =>
      await deleteBulk(`${key}/bulk`, findingsData, filters),
    onSuccess: (data: number | undefined, { onSuccessCallback }) => {
      if (data && onSuccessCallback) onSuccessCallback(data);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
    },
  });
};

export const useApiPushFindingToCustomerJira = () => {
  /// api/v1/findings/:id/push_to_customer_jira/
  const queryClient = useQueryClient();
  return useMutation<any, Error, FindingsPushToJiraContext>({
    mutationKey: ["push_to_customer_jira"],
    mutationFn: async ({
      findingsId,
      projectKey,
    }: FindingsPushToJiraContext): Promise<FindingsPushToJiraContext> =>
      await createItem(`findings/push_to_customer_jira`, {
        project_key: projectKey,
        findings_id: findingsId,
      }),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const exportFindingsTable = async (filters: {
  [key: string]: any;
}): Promise<Blob | void> => {
  const params = new URLSearchParams(filters).toString();
  return updateUrl(`findings/csv?${params}`, {}, false)
    .then((response) => response.blob())
    .catch((err) => console.error(err));
};

export const useApiCreateFinding = () => {
  const queryClient = useQueryClient();
  return useMutation<Finding, Error, AdminFindingEdit>({
    mutationKey: [key, "create"],
    mutationFn: async (newFinding): Promise<Finding> =>
      await createItem(key, newFinding),
    onSuccess: (data: Finding, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiDeleteFinding = () => {
  const queryClient = useQueryClient();
  return useMutation<Finding, Error, FindingContext>({
    mutationKey: [key],
    mutationFn: async ({
      findingId,
      findingData,
    }: FindingContext): Promise<any> =>
      await deleteItem(key, findingData, findingId),
    // onSuccess: (data, variables) =>
    onSuccess: (data: Finding, { onSuccessCallback }) => {
      removeItemFromContext({
        data,
        queryKey: [key, { id: data.id }],
        queryClient,
      });
      onSuccessCallback && onSuccessCallback(data);
    },
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
    },
  });
};

export const useApiUpdateFinding = (filters?: { [key: string]: any }) => {
  // api/v1/findings/:id/
  const queryClient = useQueryClient();
  return useMutation<Finding, Error, UpdateFindingParams>({
    mutationKey: [key, "update"],
    mutationFn: async ({ id, ...newFinding }): Promise<Finding> =>
      await updateItem(key, id, newFinding, filters),
    onSuccess: (data: Finding, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key, "update"], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiCreateFindingImages = () => {
  // api/v1/findings/:id/images/
  const queryClient = useQueryClient();
  return useMutation<FindingImage, Error, FindingImageParams>({
    mutationKey: [key, "images"],
    mutationFn: async ({ findingId, ...newImage }): Promise<FindingImage> =>
      await createItem(`${key}/${findingId}/images/`, newImage),
    onSuccess: (data: FindingImage, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key, "images"], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiCreateFindingAttachments = () => {
  // api/v1/findings/:id/attachments/
  const queryClient = useQueryClient();
  return useMutation<string, Error, FindingAttachmentCreateParams>({
    mutationKey: [key, "attachments"],
    mutationFn: async ({ findingId, ...newAttachments }): Promise<string> =>
      await createItem(`${key}/${findingId}/attachments/`, newAttachments),
    onSuccess: (data: string, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key, "attachments"], queryClient),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiFindingAttachments = (id: number | undefined) =>
  // api/v1/findings/:id/attachments/
  useQuery<string[], Error>({
    queryKey: [key, "attachments", id],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: !!id,
    queryFn: async (): Promise<string[]> =>
      getItems(`${key}/${id}/attachments/`),
  });

export const useApiSingleFindingAttachment = (
  id: number | undefined,
  attachmentName: string | undefined
) =>
  // api/v1/findings/:id/attachments?attachment_name={name}
  useQuery<string, Error>({
    queryKey: [key, "attachments", id, attachmentName],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: !!id && !!attachmentName,
    queryFn: async (): Promise<string> =>
      getItems(`${key}/${id}/attachments/`, {
        attachment_name: attachmentName,
      }),
  });

export const useApiDownloadSingleFindingAttachment = () => {
  // api/v1/findings/:id/attachments/
  return useMutation<Blob, Error, FindingAttachmentParams>({
    mutationKey: [key, "attachments", "download"],
    mutationFn: async ({
      findingId,
      ...downloadAttachmentParams
    }): Promise<Blob> =>
      await createItem(
        `${key}/${findingId}/attachments/`,
        downloadAttachmentParams,
        undefined,
        true
      ),
    onSuccess: (data: Blob, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiFindingDeleteAttachment = () => {
  // api/v1/findings/:id/attachments?attachment_name={name}
  const queryClient = useQueryClient();
  return useMutation<string, Error, FindingAttachmentParams>({
    mutationKey: [key, "attachments", "delete"],
    mutationFn: async ({ findingId, attachment_name }): Promise<string> =>
      await deleteUrl(`${key}/${findingId}/attachments/`, {
        attachment_name: attachment_name,
      }),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key, "attachments"], queryClient),

    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiAsmAnalytics = () =>
  useQuery<asmAnalytics, Error>({
    queryKey: [asmAnalyticsKey],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<asmAnalytics> => getItems(asmAnalyticsKey),
  });

export const useApiSecurityBenchmark = () =>
  useQuery<secBenchmarkMetrics, Error>({
    queryKey: [secBenchmarkKey],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<secBenchmarkMetrics> =>
      getItems(secBenchmarkKey),
  });

export const useApiMergeFindings = () => {
  const queryClient = useQueryClient();

  return useMutation<FindingsMergeOutput, Error, FindingMergeContext>({
    mutationKey: [key, "merge"],
    mutationFn: async ({
      findingsData,
    }: FindingMergeContext): Promise<FindingsMergeOutput> =>
      await createItem(`${key}/merge`, findingsData),
    onSuccess: (data, { onSuccessCallback: onSuccessCallBack }) => {
      if (onSuccessCallBack) {
        onSuccessCallBack(data);
      }
    },
    onError: (error, { onErrorCallback: onErrorCallBack }) => {
      if (onErrorCallBack) {
        onErrorCallBack(error);
      }
    },
    onSettled: () => {
      invalidateApiQueries([key], queryClient);
    },
  });
};
