import React, { useRef } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { chatMessage } from "./chatMessage";
import {
  ConversationInfo,
  ConversationMessage,
  ConversationsPage,
} from "../context/AIContext";
import { ChatMessage } from "./ChatMessage";
import { Loading } from "../../../components/elements/loading/Loading";
import { useApiMe } from "../../../hooks/queries/meContext";
import { ChatWelcomeBanner } from "./ChatWelcomeBanner";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";

type Props = {
  mode: string;
  chatMessages: chatMessage[];
  pastConversation: (string | ConversationInfo | ConversationMessage)[];
  latestUserMessage: string;
  isFetchingConversation: boolean;
  isRefetchingConversation: boolean;
  hasNextPageConversation: boolean | undefined;
  fetchNextPageConversation: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<ConversationsPage, Error>>;
  isFetchingNextPageConversation: boolean;
  isLoadingAnswerFromAI: boolean;
};

export const WaspAIChatWindow = (props: Props) => {
  const {
    mode,
    chatMessages,
    pastConversation,
    latestUserMessage,
    isFetchingConversation,
    isRefetchingConversation,
    hasNextPageConversation,
    fetchNextPageConversation,
    isFetchingNextPageConversation,
    isLoadingAnswerFromAI,
  } = props;
  const { data: me } = useApiMe();

  const observerElemForFetchPage = useRef(null);
  useInfiniteScroll(
    observerElemForFetchPage,
    !!hasNextPageConversation,
    fetchNextPageConversation
  );

  return (
    <Flex
      w100
      style={{
        height: "85%",
        overflowY: "scroll",
        paddingRight: "12px",
      }}
      column
    >
      <Flex
        column
        gap="16px"
        w100
        h100
        style={{
          paddingInline: mode === "Full Screen" ? "15%" : "0%",
        }}
      >
        {/* Show welcome banner if no messages can be shown */}
        {chatMessages.length === 0 &&
          (pastConversation ? pastConversation.length === 0 : true) &&
          !isLoadingAnswerFromAI && (
            <Flex h100 w100 justify="center" align="center">
              <ChatWelcomeBanner />
            </Flex>
          )}
        {/* Show past chat messages if possible */}
        {pastConversation &&
          pastConversation.map((message, index) => {
            // Check if the message is of type ConversationMessage
            if (typeof message === "object" && "sender" in message) {
              return (
                <ChatMessage
                  key={index}
                  chatMessage={{
                    role: message.sender,
                    text: message.content,
                    fullName: `${me?.first_name} ${me?.last_name}`,
                  }}
                />
              );
            } else {
              return null;
            }
          })}
        {/* New messages will added here */}
        {chatMessages &&
          chatMessages.map((message, index) => (
            <ChatMessage key={index} chatMessage={message} />
          ))}
        {isLoadingAnswerFromAI && (
          <>
            <ChatMessage
              chatMessage={{
                text: latestUserMessage,
                role: "user",
              }}
            />
            <ChatMessage
              chatMessage={{
                text: "Thinking...",
                role: "assistant",
              }}
            />
          </>
        )}
        {/* Observer element for fetching - Note: border ensures reliable observability */}
        <div
          className="d-flex"
          ref={observerElemForFetchPage}
          style={{ border: "1px solid transparent" }}
        />
      </Flex>
      {(isFetchingConversation ||
        isRefetchingConversation ||
        isFetchingNextPageConversation) && <Loading />}
    </Flex>
  );
};
