import { useContext } from "react";
import {
  NotificationEventType,
  NotificationReceiver,
} from "../../../types/Notifications";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import {
  BodySemiBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { Badge } from "../../../components/elements/badge/Badge";
import { getDateTime, getTime } from "../../../shared/helper";
import { NewNotificationTip } from "./NewNotificationTip";
import { useNavigate } from "react-router";
import {
  useApiNotificationMarkRead,
  useApiNotificationReceiversPaging,
  useApiNotificationsCountUnread,
} from "../context/notificationReceiverContext";
import useToastContext from "../../../hooks/toastHook";
import { Icon } from "../../../components/elements/icon/Icon";
import { NotificationItemDetails } from "./NotificationItemDetails";
import { Mixpanel } from "../../../shared/mixpanel";
import { useApiSingleCustomer } from "../../../hooks/queries/customersContext";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { useIsPresentationMode } from "../../../hooks/useIsPresentationMode";

type NotificationTypeStyle = {
  icon: string;
  background: string;
  color: string;
  text: string;
};

const NotificationTypeStyles: {
  [key in NotificationEventType]: NotificationTypeStyle;
} = {
  FINDING_CREATED: {
    icon: "plus",
    color: "greenPrimary",
    background: "green50",
    text: "Created Finding",
  },
  FINDING_DELETED: {
    icon: "trash",
    color: "redPrimary",
    background: "red50",
    text: "Deleted Finding",
  },
  FINDING_UPDATED: {
    icon: "edit",
    color: "orangePrimary",
    background: "orange50",
    text: "Changed Finding",
  },
  COMMENT_CREATED: {
    icon: "comment",
    color: "lightBluePrimary",
    background: "lightBlue50",
    text: "New Comment",
  },
  COMMENT_MENTION: {
    icon: "comment",
    color: "lightBluePrimary",
    background: "lightBlue50",
    text: "You are mentioned",
  },
  SENT_TO_JIRA: {
    icon: "jira",
    color: "blue",
    background: "blue50",
    text: "Sent to Jira",
  },
  BREACHED_SLA: {
    icon: "clock",
    color: "redPrimary",
    background: "red50",
    text: "Breached SLA",
  },
};

type Props = {
  notificationReceiver: NotificationReceiver;
  onClose: () => void;
};

export const NotificationItem = (props: Props) => {
  const { notificationReceiver, onClose } = props;
  const theme = useContext(ThemeContext);
  const isSuperuser = useIsSuperuser();
  const isPresentationMode = useIsPresentationMode();
  const addToast = useToastContext();
  const navigate = useNavigate();

  const { refetch: refetchNotifications } = useApiNotificationReceiversPaging();
  const { refetch: refetchNotificationsCount } =
    useApiNotificationsCountUnread();
  const { mutate: markRead } = useApiNotificationMarkRead();
  const { data: customer } = useApiSingleCustomer(
    isSuperuser && notificationReceiver.notification_event.customer
      ? notificationReceiver.notification_event.customer
      : undefined
  );

  const onClickNotification = () => {
    if (!!notificationReceiver.id) {
      const findingId =
        notificationReceiver?.notification_event?.event_details?.finding &&
        notificationReceiver.notification_event.event_details.finding.id;

      const assetId =
        notificationReceiver?.notification_event?.event_details?.asset &&
        notificationReceiver.notification_event.event_details.asset.id;

      markRead({
        notificationReceiverId: notificationReceiver.id,
        notificationReceiverData: { is_read: true },
        onSuccessCallback(data) {
          refetchNotifications();
          refetchNotificationsCount();
        },
        onErrorCallback(error) {
          addToast({
            type: "error",
            message: `Failed to interact with notification: ${error}`,
          });
        },
      });
      onClose();

      if (findingId) {
        if (
          notificationReceiver.notification_event.event_type !==
          "FINDING_DELETED"
        )
          navigate(
            `finding-details/${findingId}${isSuperuser ? "?admin-mode=true" : ""}`
          );
      } else if (assetId) {
        navigate(
          `assets?assetId=${assetId}&commentId=${
            notificationReceiver.notification_event.event_details?.comment_id ||
            1
          }${isSuperuser ? "&admin-mode=true" : ""}`
        );
      }

      Mixpanel.track("Notifications - Item Clicked", {
        notificationId: notificationReceiver.id,
        notificationType: notificationReceiver.notification_event.event_type,
      });
    }
  };

  return (
    <Flex
      column
      onClick={onClickNotification}
      style={{
        minWidth: "443px",
        minHeight: "119px",
        borderTop: `1px solid ${theme.separation}`,
        cursor: "pointer",
      }}
    >
      <NewNotificationTip isRead={notificationReceiver.is_read || false} />
      <Flex column padding="8px">
        <Flex justify="between">
          <LabelRegular color={theme.black600}>
            {notificationReceiver?.notification_event.created_at
              ? `${
                  getDateTime(
                    notificationReceiver?.notification_event.created_at
                  ) +
                  ", " +
                  getTime(notificationReceiver?.notification_event.created_at)
                }`
              : "Date not found"}
          </LabelRegular>

          {notificationReceiver.notification_event.event_details?.finding
            ?.is_automated && (
            <Icon name="wasp" color={theme.yellowPrimary} size={24} />
          )}

          {notificationReceiver.notification_event.event_type && (
            <Badge
              className="text-truncate text-capitalize"
              style={{
                maxWidth: "50%",
              }}
              color={
                theme[
                  NotificationTypeStyles[
                    notificationReceiver.notification_event.event_type
                  ].color
                ]
              }
              backgroundColor={
                theme[
                  NotificationTypeStyles[
                    notificationReceiver.notification_event.event_type
                  ].background
                ]
              }
            >
              {
                NotificationTypeStyles[
                  notificationReceiver.notification_event.event_type
                ].text
              }
            </Badge>
          )}
        </Flex>
        <Flex gap="8px" column>
          <BodySemiBold color={theme.black900}>
            {notificationReceiver.notification_event.event_details.finding
              ?.title ||
              notificationReceiver.notification_event.event_details.asset
                ?.asset_name ||
              ""}
          </BodySemiBold>
          <NotificationItemDetails
            notificationReceiver={notificationReceiver}
          />
        </Flex>
      </Flex>
      <Flex w100 justify="end">
        {customer && isSuperuser && !isPresentationMode && (
          <Badge
            className="text-truncate text-capitalize"
            style={{
              maxWidth: "50%",
            }}
            color={theme.lightBluePrimary}
            backgroundColor={theme.lightBlue50}
          >
            {customer.name}
          </Badge>
        )}
      </Flex>
    </Flex>
  );
};
